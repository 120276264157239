import { Component } from '@angular/core';

@Component({
  selector: 'business-component',
  templateUrl: './business.component.html',
  styles: [`
    .features-box-icon {
      background: white;
      height: 50px;
      margin-bottom: 40px;
      padding-top: 15px;
      text-align: center;
      width: 150px;
    }
    .features-box-icon img {
      max-width: 150px;
      max-height: 60px;
    }
  `]
})
export class BusinessComponent {
//   title = 'home';

  public data: any = [
    {name: 'Comisión Nacional del Agua', logo: 'GOBMEX_CONAGUA.png', link: 'https://www.gob.mx/conagua'},
    {name: 'Servicio Meteorológico Nacional', logo: 'smn_logo.jpg', link: 'https://smn.conagua.gob.mx/es/'},
    {name: 'Secretaria de Desarrollo Agroalimentario y Rural del Estado de Guanajuato', logo: 'unnamed.png', link: 'https://sdayr.guanajuato.gob.mx/'},
    {name: 'Fideicomisos Instituidos en Relación con la Agricultura (FIRA)', logo: 'Logo175x70azul-osc.png', link: 'https://fira.gob.mx/Nd/index.jsp'},
    {name: 'Comisión Estatal del Agua en el Estado de Guanajuato', logo: 'ceag.png', link: 'http://agua.guanajuato.gob.mx/'},
    {name: 'Centro Internacional de Mejoramiento de Maíz y Trigo (CIMMYT)', logo: 'cimmyt.png', link: 'https://www.cimmyt.org/es/'},
    {name: 'Ökostrom', logo: 'okostrom-blue.png', link: 'https://www.okostrom.com/'},
    // {name: 'Green Climate Fund (GCF)', logo: 'logo-type-bk.png', link: 'https://www.greenclimate.fund/'},
    {name: 'Cause Bajío Fondos de Agua', logo: 'logo2-01.png', link: 'https://caucebajio.com/blog/'},
    // {name: 'Laboratorio de Agua  Enzo Levi (Instituto Mexicano de Tecnología del Agua)', logo: '', link: 'http://labhidraulica.imta.mx/'},
    // {name: 'Biblioteca Centro del Conocimiento del Agua (CENCA)', logo: 'Logo_Cenca.jpg', link: 'http://cenca.imta.mx/'},
  ]

}