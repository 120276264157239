import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'risur-app',
  templateUrl: './risur.component.html',
  styleUrls: ['./risur.component.css']
})
export class RisurComponent implements OnInit {

  meta_title = 'CEIR - RISUR: Riego por Surcos';
  meta_description = 'RISUR es un software que se diseño con el fin de visualizar de forma gráfica el comportamiento del riego de parcelas por surcos, ya que permite la variación de los parámetros que intervienen. Convirtiéndose en una herramienta potencial y eficiente para la Asistencia Técnica en Riego y ofrecer a los productores del estado de Guanajuato un servicio técnico de calidad, el cual le permita hacer uso eficiente del agua y otros recursos.';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }
  
  ngOnInit() {
    this.titleService.setTitle(this.meta_title);
  
    this.metaTagService.addTags([

      { charset: 'UTF-8' },
      // { name: 'robots', content: 'index, follow' },
      // { name: 'author', content: 'Hardik Savani' },

      { name: 'description', content: this.meta_description },
      { name: 'keywords', content: 'CEIR, App, Riego, Especialización, Innovación, Gravedad, Aspersión, Goteo, Cursos' },
      
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@recktoner' },
      { name: 'twitter:creator', content: '@recktoner' },
      { name: 'twitter:title', content: this.meta_title },
      { name: 'twitter:description', content: this.meta_description },
      { name: 'twitter:image', content: 'http://www.ceir.mx/assets/img/RISUR.png' },
      { name: 'twitter:url', content: 'http://www.ceir.mx' },

      { name: 'og:title', content: this.meta_title },
      { name: 'og:description', content: this.meta_description },
      { name: 'og:image', content: 'http://www.ceir.mx/assets/img/RISUR.png' },
      { name: 'og:image:width', content: '500' },
      { name: 'og:image:height', content: '500' },
      { name: 'og:url', content: 'http://www.ceir.mx' },
      { name: 'og:site_name', content: 'http://www.ceir.mx' },
      { name: 'og:type', content: 'website' },

      { name: 'fb:admins', content: 'victor.valencia.rico' }      
      
    ]);
      
  }

}