<!--COURSE AREA-->
<section class="course-area padding-top section-padding" id="courses">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                <div class="area-title text-center wow fadeIn">
                    <h2 class="xs-font20">Nuestros cursos y diplomados de tendencia</h2>
                </div>
            </div>
        </div>
        <div class="row course-lists">
            <div *ngFor="let item of data_pt1" class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div class="single-course mb20">
                    <img src="{{item.image}}" alt="">
                    <div class="course-details padding30">
                        <h3 class="course-click font18 title-link" data-title="{{item.title}}@{{item.flyer}}">{{item.title}}</h3>
                        <p class="course-description">{{item.description}}</p>                        
                        <p class="mt30">                            
                            <a *ngIf="item.registro == null && item.flyer == null" href="#" class="proximamente-button">Proximamente</a>
                            <a *ngIf="item.registro != null" href="{{item.registro}}" target="_blank" class="enroll-button">Inscribirse al curso</a>
                            <a *ngIf="item.flyer != null"href="javascript:;" class="course-click more-detail-button button-right" data-title="{{item.title}}@{{item.flyer}}">Más información</a>
                        </p>
                        <p *ngIf="item.ficha != null" class="mt30" style="text-align: {{(item.registro == null) ? 'left': 'center'}};">
                            <a href="{{item.ficha}}" target="_blank" class="more-detail-button" style="width: 80%; background-color: #bdc3c7; color: #34495e;">Ficha técnica</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row course-lists">
            <div *ngFor="let item of data_pt2" class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                <div class="single-course mb20">
                    <img src="{{item.image}}" alt="">
                    <div class="course-details padding30">
                        <h3 class="course-click font18 title-link" data-title="{{item.title}}@{{item.flyer}}">{{item.title}}</h3>
                        <p class="course-description">{{item.description}}</p>
                        <p class="mt30">
                            <a *ngIf="item.registro == null && item.flyer == null" href="#" class="proximamente-button">Proximamente</a>
                            <a *ngIf="item.registro != null" href="{{item.registro}}" target="_blank" class="enroll-button">Inscribirse al curso</a>
                            <a *ngIf="item.flyer != null"href="javascript:;" class="course-click more-detail-button button-right" data-title="{{item.title}}@{{item.flyer}}">Más información</a>
                        </p>
                        <p *ngIf="item.ficha != null" class="mt30" style="text-align: {{(item.registro == null) ? 'left': 'center'}};">
                            <a href="{{item.ficha}}" target="_blank" class="more-detail-button" style="width: 80%; background-color: #bdc3c7; color: #34495e;">Ficha técnica</a>
                        </p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!--COURSE AREA END-->

<!--ABOUT AREA-->
<section class="section-padding about-area bg-theme section-padding" id="about">
    <div class="container">
        <div class="row flex-v-center">
            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                <div class="about-content xs-mb50 wow fadeIn">
                    <h3 class="xs-font20 mb30">La educación es la base sobre la que construimos nuestro futuro.</h3>
                    <p>La educación en línea se ha convertido en una excelente opción para finalizar tus estudios o alcanzar tu formación profesional. Tomar cursos a distancia es actualmente una realidad. Capacítate en el <b>Manejo eficiente del riego por gravedad</b>, un curso que te proporciona las bases para que realices diseños que optimizan el uso del agua a nivel parcelario.</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-md-offset-1 col-lg-offset-1 col-sm-12 col-xs-12">
                <span class="youtube-link" youtubeid="MvYru9x_09A">
                    <div class="video-promo-details wow fadeIn">
                        <div class="video-promo-content">
                            <span class="video-area-popup mb30"><i class="fa fa-play"></i></span>
                        </div>
                        <img src="assets/img/video_02.png" alt="">
                    </div>
                </span>
            </div>
        </div>
    </div>
</section>
<!--ABOUT AREA END-->

<!--TESTMONIAL AREA AREA-->
<!--<section class="testmonial-area bg-theme section-padding" id="testmonial">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                <div class="area-title text-center wow fadeIn">
                    <h2>Lo que dicen nuestros clientes</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="testmonial-slider">
                    <div class="single-testmonial">
                        <div class="author-content mb20">
                            <p>“Aprendi a instalar y darle mantenimiento a los sistemas de riego por goteo en la zona Laja-Bajío.”</p>
                        </div>
                        <div class="author-name-image relative">
                            <div class="author-img mb20">
                                <img src="assets/img/testmonial/testmonial_3.jpg" alt="">
                            </div>
                            <h4>Juan Antonio Rodriguez</h4>
                            <a href="#">Estudiante de Hidrología</a>
                        </div>
                    </div>
                    <div class="single-testmonial">
                        <div class="author-content mb20">
                            <p>“Logré hacer uso eficiente del agua en mi rancho, y lo mejor es que pude ahorrar dinero aplicando los conocimientos obtenidos.”</p>
                        </div>
                        <div class="author-name-image relative">
                            <div class="author-img mb20">
                                <img src="assets/img/testmonial/testmonial_2.jpg" alt="">
                            </div>
                            <h4>Maricela Contreras</h4>
                            <a href="#">Productora</a>
                        </div>
                    </div>
                    <div class="single-testmonial">
                        <div class="author-content mb20">
                            <p>“Colaboré con otros técnicos y aprendí la importancia que tiene el agua en el estado de Guanajuato. Ahora, transmito lo aprendido a mis productores.”</p>
                        </div>
                        <div class="author-name-image relative">
                            <div class="author-img mb20">
                                <img src="assets/img/testmonial/testmonial_1.jpg" alt="">
                            </div>
                            <h4>Fernando Aguirre</h4>
                            <a href="#">Técnico en Riego</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!--TESTMONIAL AREA AREA END-->

<!--BLOG AREA-->
<!--<section class="blog-feed-area padding-top section-padding" id="blog">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                <div class="area-title text-center wow fadeIn">
                    <h2>Notas de Interés</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="single-blog-item sm-mb30 xs-mb30 wow fadeInUp" data-wow-delay="0.4s">
                    <div class="blog-thumb">
                        <a href="https://www.e-ducatek.com/" target="_blank"><img src="assets/img/blog/educatek.png" alt=""></a>
                    </div>
                    <div class="blog-details padding30">
                        <h3 class="blog-title font20 mb30"><a href="https://www.e-ducatek.com/" target="_blank">Aliado estratégico de CEIR es EDUCATEK (innovando la educación).</a></h3>
                        <p class="blog-meta font14 mt20"><a href="#">16 Mar 2021</a> por <a href="#">Miguel Marquez Zarate</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="single-blog-item sm-mb30 xs-mb30 wow fadeInUp" data-wow-delay="0.2s">
                    <div class="blog-thumb">
                        <a href="javascript:;"><img src="assets/img/blog/blog-4.png" alt=""></a>
                    </div>
                    <div class="blog-details padding30">
                        <h3 class="blog-title font20 mb30"><a href="javascript:;">El agua comienza a cotizar en el mercado de futuros de Wall Street</a></h3>
                        <p class="blog-meta font14 mt20"><a href="#">08 Dic 2020</a> por <a href="#">Gustavo Magaña S</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="single-blog-item sm-mb30 xs-mb30 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="blog-thumb">
                        <a href="javascript:;"><img src="assets/img/blog/blog-5.png" alt=""></a>
                    </div>
                    <div class="blog-details padding30">
                        <h3 class="blog-title font20 mb30"><a href="javascript:;">Análisis de aguas residuales revela pistas sobre infecciones de Covid-19</a></h3>
                        <p class="blog-meta font14 mt20"><a href="#">07 Dic 2020</a> por <a href="#">Bernardo Muñoz H</a></p>
                    </div>
                </div>
            </div>                            
        </div>
    </div>
</section>-->
<!--BLOG AREA END-->

<!--APPS AREA-->
<section class="course-area padding-top section-padding" id="apps">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                <div class="area-title text-center wow fadeIn">
                    <h2 class="xs-font26">Aplicaciones</h2>
                </div>
            </div>
        </div>
        <div class="row course-list">
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                <div class="single-course mb20">
                    <img src="assets/img/course/blog-9.png" alt="">
                    <div class="course-details padding30">
                        <h3 class="font18">OptiRiego</h3>
                        <p>Software especializado para la generación de recetas de riego...</p>
                        <p class="mt30"><a href="http://optiriego.ceir.mx" target="_blank" class="enroll-button">Visitar app</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                <div class="single-course mb20">
                    <img src="assets/img/course/blog-0.png" alt="">
                    <div class="course-details padding30">
                        <h3 class="font18">Digitalizar KML</h3>
                        <p>Software especializado para la digitalización de areas de trabajo en el campo...</p>
                        <p class="mt30"><a href="http://kml.ceir.mx" target="_blank" class="enroll-button">Visitar app</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12">
                <div class="single-course mb20">
                    <img id="img21" src="assets/img/course/blog-8.png" alt="">
                    <div class="course-details padding30">
                        <h3 class="font18">RISUR</h3>
                        <p>Simulación de Riego por Gravedad...</p>
                        <p class="mt30"><a href="http://risur.ceir.mx" target="_blank" class="enroll-button">Descargar Software</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--APPS AREA END-->

<!--FAQS AREA-->
<section class="faqs-area padding-100-50 bg-theme" id="faqs">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="area-title text-center wow fadeIn">
                    <h2>Preguntas</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 class="box-title">1. ¿Puedo acceder a los cursos desde cualquier dispositivo?</h3>
                    <p>Por supuesto, puedes acceder a todos nuestros cursos desde cualquier dispositivo en la comodidad de tu hogar.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.2s">
                    <h3 class="box-title">2. ¿Cuales son los medios de pago disponibles?</h3>
                    <p>Contamos con diversas con diversas formas de pago para que no pierdas la oportunidad de inscribirte a nuestros cursos en línea. Ponte en contacto en la página de <a href="https://forms.office.com/r/EjLf7ZvLj3" target="_blank">E-ducatek</a>.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.3s">
                    <h3 class="box-title">3. Si tengo dudas, ¿A quien puedo consultar durante el curso?</h3>
                    <p>No te preocupes, todos nuestros instructores se encontraran disponibles durante el desarrollo del curso para atender tus dudas en un tiempo considerable.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">

            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 class="box-title">4. ¿Que documentos necesito presentar?</h3>
                    <p>No es necesario enviar ningún tipo de documento, en CEIR compartimos el conocimiento para todos los niveles educativos.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                
            </div>
            <!-- <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.2s">
                    <h3 class="box-title">2.What Does Darwin Mean to You?</h3>
                    <p>A Google Docs scam that appears to be widespread began landing in users’ inboxes on Wednesday in what seemed to be a sophisticated phishing.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb50 wow fadeInUp" data-wow-delay="0.3s">
                    <h3 class="box-title">3.Where Totem Poles Are a Living Art ?</h3>
                    <p>A Google Docs scam that appears to be widespread began landing in users’ inboxes on Wednesday in what seemed to be a sophisticated phishing.</p>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!--FAQS AREA END-->

<!--CONTACT US AREA-->
<section class="contact-area sky-gray-bg" id="contact">
    <div class="container-fluid no-padding">
        <div class="row no-margin">
            <!-- <div class="no-padding col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="map-area relative">
                    <div id="map" style="width: 100%; height: 600px;"> </div>
                </div>
            </div> -->
            <div class="no-padding col-md-3 col-lg-3">

            </div>
            <div class="no-padding col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <div class="contact-form-content padding50 xs-padding20">
                    <div class="contact-title wow fadeIn">
                        <h3 class="font28 mb50 xs-mb30 xs-font22 xs-mt20">Alguna pregunta en mente? No dudes en contactarnos.</h3>
                    </div>
                    <div class="contact-form wow fadeIn">
                        <form action="process.php" id="contact-form" method="post">
                            <div class="row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    <div class="form-group" id="name-field">
                                        <div class="form-input">
                                            <input type="text" class="form-control" id="form-name" name="form-name" placeholder="Nombre.." required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    <div class="form-group" id="email-field">
                                        <div class="form-input">
                                            <input type="email" class="form-control" id="form-email" name="form-email" placeholder="Correo electrónico.." required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div class="form-group" id="phone-field">
                                        <div class="form-input">
                                            <input type="text" class="form-control" id="form-phone" name="form-phone" placeholder="Asunto..">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div class="form-group" id="message-field">
                                        <div class="form-input">
                                            <textarea class="form-control" rows="6" id="form-message" name="form-message" placeholder="Escribe tu mensaje aquí..." required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div class="form-group mb0">
                                        <button type="submit">Enviar Mensaje</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--CONTACT US AREA END-->

<!--CLIENT AREA-->
<!-- <div class="client-area  padding-bottom mt100 sm-mt10 xs-mt0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div class="client-slider">
                    <div class="single-client">
                        <img src="assets/img/client/client-6.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-7.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-8.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-9.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-10.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-6.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-7.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-8.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-9.png" alt="">
                    </div>
                    <div class="single-client">
                        <img src="assets/img/client/client-10.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!--CLIENT AREA END-->

<!--ADDMISSION AREA-->
<section class="admition-area padding-50-50 bg-theme">
    <div class="container">
        <div class="row flex-v-center">
            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div class="admition-content xs-center xs-mb30">
                    <h3 class="xs-font20">Inscripciones Abiertas</h3>
                    <p>Inscibete a nuestros cursos y no pierdas la oportunidad de ampliar tus conocimientos.</p>
                </div>
            </div>
            <!--<div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                <div class="enroll-button right xs-center">
                    <a href="#">Inscribete Ahora</a>
                </div>
            </div>-->
        </div>
    </div>
</section>
<!--ADDMISSION AREA END-->               
