<!--START TOP AREA-->
<header class="top-area" id="home">
    <div class="header-top-area" id="scroolup">
        <!--MAINMENU AREA-->
        <div class="mainmenu-area" id="mainmenu-area">
            <div class="mainmenu-area-bg"></div>
            <nav class="navbar">
                <div class="container">
                    <div class="navbar-header">
                        <a href="#home" class="navbar-brand"><img src="assets/img/logo.png" alt="logo"></a>
                    </div>
                    <div id="main-nav" class="stellarnav">
                        <ul id="nav" class="nav navbar-nav pull-right">
                            <li class="active"><a href="#home">Inicio</a></li>
                            <!-- <li><a href="#features">Características</a></li> -->
                            <li><a href="#courses">Cursos</a></li>
                            <!-- <li><a href="#testmonial">Testimoniales</a></li>
                            <li><a href="#blog">Notas de Interés</a></li> -->
                            <li><a href="#apps">Aplicaciones</a></li>
                            <li><a href="#faqs">Preguntas</a></li>
                            <li><a href="#contact">Contacto</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <!--END MAINMENU AREA END-->
    </div>

    <!--WELCOME SLIDER AREA-->
    <div class="welcome-slider-area white font16">
        <div class="welcome-single-slide">
            <div class="slide-bg-one slide-bg-overlay"></div>
            <div class="welcome-area">
                <div class="container">
                    <div class="row flex-v-center">
                        <div class="col-md-8 col-lg-7 col-sm-12 col-xs-12">
                            <div class="welcome-text">
                                <h1>Centro de Estudios e Innovación en Riego.</h1>
                                <p>CEIR ayuda a organizaciones y personas a prepararse para un futuro laboral en cambio constante. Contamos con una amplia colección de cursos y de técnicos capacitados en el ambito del riego.</p>
                                <div class="home-button">
                                    <form action="#">
                                        <input type="search" name="search" id="search" placeholder="Buscar cursos">
                                        <button type="submit"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="welcome-single-slide">
            <div class="slide-bg-two slide-bg-overlay"></div>
            <div class="welcome-area">
                <div class="container">
                    <div class="row flex-v-center">
                        <div class="col-md-8 col-lg-7 col-sm-12 col-xs-12">
                            <div class="welcome-text">
                                <h1>Centro de Estudios e Innovación en Riego.</h1>
                                <p>CEIR ayuda a organizaciones y personas a prepararse para un futuro laboral en cambio constante. Contamos con una amplia colección de cursos y de técnicos capacitados en el ambito del riego.</p>
                                <div class="home-button">
                                    <form action="#">
                                        <input type="search" name="search" id="search" placeholder="Buscar cursos">
                                        <button type="submit"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="welcome-single-slide">
            <div class="slide-bg-three slide-bg-overlay"></div>
            <div class="welcome-area">
                <div class="container">
                    <div class="row flex-v-center">
                        <div class="col-md-8 col-lg-7 col-sm-12 col-xs-12">
                            <div class="welcome-text">
                                <h1>Centro de Estudios e Innovación en Riego.</h1>
                                <p>CEIR ayuda a organizaciones y personas a prepararse para un futuro laboral en cambio constante. Contamos con una amplia colección de cursos y de técnicos capacitados en el ambito del riego.</p>
                                <div class="home-button">
                                    <form action="#">
                                        <input type="search" name="search" id="search" placeholder="Buscar cursos">
                                        <button type="submit"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--WELCOME SLIDER AREA END-->
</header>
<!--END TOP AREA-->