import { Component } from '@angular/core';

@Component({
  selector: 'courses-component',
  templateUrl: './courses.component.html',
//   styleUrls: ['./home.component.css']
})
export class CoursesComponent {
//   title = 'home';

  public data_pt1: any = [
    {
      class: "course_06",
      image: "assets/img/course/blog-10.jpg",
      title: "Curso: Manejo del agua en condiciones de escasez en Distritos de Riego. Zona del Río Bravo",
      description: "Aportar los conocimientos necesarios para que los participantes adquieran la capacidad de gestionar de manera eficiente la distribución y el manejo del agua en los distritos de Riego bajo condiciones de escasez.",
      flyer: "assets/img/flyers/Flyer_CEIR_06.jpg",
      registro: "http://admin.ceir.mx/registro/cb7361a8-9ebc-5d91-8395-9a8acdcded48",
      ficha: "pdf/Ficha_CEIR_06.pdf"
    },
    {
      class: "course_05",
      image: "assets/img/course/blog-10.jpg",
      title: "Curso: Manejo del agua en condiciones de escasez en Distritos de Riego. Zona del Río Lerma",
      description: "Aportar los conocimientos necesarios para que los participantes adquieran la capacidad de gestionar de manera eficiente la distribución y el manejo del agua en los distritos de Riego bajo condiciones de escasez.",
      flyer: "assets/img/flyers/Flyer_CEIR_05.jpg",
      registro: null, //"http://admin.ceir.mx/registro/5829bda1-932a-5626-84fc-524569087c23",
      ficha: "pdf/Ficha_CEIR_05.pdf"
    },
    {
      class: "course_03",
      image: "assets/img/course/blog-10.jpg",
      title: "Curso: Manejo del agua en condiciones de escasez en Distritos de Riego. Zona Noroeste",
      description: "Aportar los conocimientos necesarios para que los participantes adquieran la capacidad de gestionar de manera eficiente la distribución y el manejo del agua en los distritos de Riego bajo condiciones de escasez.",
      flyer: "assets/img/flyers/Flyer_CEIR_03.jpg",
      registro: null, //"http://admin.ceir.mx/registro/4e87a152-0e61-5814-aa2c-43fa86d5486e",
      ficha: "pdf/Ficha_CEIR_03.pdf"
    },
    {
      class: "course_04",
      image: "assets/img/course/blog-6.png",
      title: "Curso: Necesidades de Agua de los Cultivos",
      description: "Aportar los conocimientos necesarios para que los participantes adquieran la capacidad de calcular las necesidades de agua que requieren los cultivos, información básica y necesaria para el diseño de un sistema de riego.",
      flyer: "assets/img/flyers/Flyer_CEIR_04.jpg",
      registro: null, //"http://admin.ceir.mx/registro/cf916877-61e7-542b-81d8-5cc1cd90c0c3",
      ficha: "pdf/Ficha_CEIR_04.pdf"
    },
  // ];

  // public data_pt2: any = [    
    {
      class: "course_01",
      image: "assets/img/course/blog-7.png",
      title: "Curso: Manejo Eficiente del Riego por Gravedad",
      description: "Conocer el procedimiento de diseño de riego por gravedad, a fin que los prestadores de servicios profesionales orienten a los productores para que maximice el uso eficiente del agua y la energía y obtengan mayores ingresos.",
      flyer: "assets/img/flyers/Flyer_CEIR_01.jpg",
      registro: null, //"https://forms.office.com/r/EjLf7ZvLj3",
      ficha: "pdf/Ficha_CEIR_01.pdf"
    },
    {
      class: "course_02",
      image: "assets/img/course/blog-5.png",
      title: "Diplomado en Diseño de Riego por Goteo",
      description: "Generar profesionales altamente capacitados en el diseño y evaluación técnica de proyectos de riego por goteo que cubran las demandas del sector productivo, con una orientación a la innovación y mejora continua.",
      flyer: null,
      registro: null,
      ficha: null
    }
  ]
}