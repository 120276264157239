import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'home-app',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  meta_title = 'CEIR - Centro de Estudios e Innovación en Riego';
  meta_description = 'CEIR ayuda a organizaciones y personas a prepararse para un futuro laboral en cambio constante. Contamos con una amplia colección de cursos y de técnicos capacitados en el ambito del riego.';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }
  
  ngOnInit() {
    this.titleService.setTitle(this.meta_title);
  
    this.metaTagService.addTags([

      { charset: 'UTF-8' },
      // { name: 'robots', content: 'index, follow' },
      // { name: 'author', content: 'Hardik Savani' },

      { name: 'description', content: this.meta_description },
      { name: 'keywords', content: 'CEIR, App, Riego, Especialización, Innovación, Gravedad, Aspersión, Goteo, Cursos' },
      
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@recktoner' },
      { name: 'twitter:creator', content: '@recktoner' },
      { name: 'twitter:title', content: this.meta_title },
      { name: 'twitter:description', content: this.meta_description },
      { name: 'twitter:image', content: 'http://www.ceir.mx/assets/img/video_02.png' },
      { name: 'twitter:url', content: 'http://www.ceir.mx' },

      { name: 'og:title', content: this.meta_title },
      { name: 'og:description', content: this.meta_description },
      { name: 'og:image', content: 'http://www.ceir.mx/assets/img/video_02.png' },
      { name: 'og:image:width', content: '500' },
      { name: 'og:image:height', content: '500' },
      { name: 'og:url', content: 'http://www.ceir.mx' },
      { name: 'og:site_name', content: 'http://www.ceir.mx' },
      { name: 'og:type', content: 'website' },

      { name: 'fb:admins', content: 'victor.valencia.rico' }      
      
    ]);
      
  }

}