import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RisurComponent } from './risur/risur.component';

import { HeaderComponent, AboutComponent, CoursesComponent, FooterComponent, BusinessComponent } from './components';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RisurComponent,

    HeaderComponent,
    AboutComponent,
    CoursesComponent,
    BusinessComponent,
    FooterComponent  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
