<!--BUSINESS AREA-->
<section class="features-top-area section-padding bussines-section" id="business">
    <h3 class="xs-font20 text-center">Enlaces de Interés</h3>
    <div class="container">
        <div class="row promo-content" style="margin-top: 0px;">            
            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3" *ngFor="let item of data">
                <div class="text-icon-box mb20 xs-mb0 wow fadeInUp padding30 text-center" data-wow-delay="0.1s">
                    <a class="box-icon features-box-icon" href="{{item.link}}" target="_blank">
                        <img src="assets/img/business/{{item.logo}}"/>
                    </a>
                    <!--<p>{{item.name}}</p>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--BUSINESS AREA END-->