<!--FOOTER AREA-->
<footer class="footer-area sky-gray-bg relative">
    <div class="footer-top-area padding-80-80 bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12">
                    <div class="single-footer footer-about sm-mb50 xs-mb50 sm-center xs-center">
                        <div class="footer-logo mb30">
                            <a href="#"><img src="assets/img/logo.png" alt=""></a>
                        </div>
                        <p>Centro de Estudios e Innovación en Riego.</p>
                        <!-- <p>Irrigación #209, Zona Centro, Celaya, Guanajuato, México.</p> -->
                        <p>contacto@ceir.mx</p>
                    </div>
                </div>
                <div class="col-md-2 col-lg-2 col-sm-4 col-xs-12">
                    <!-- <div class="single-footer footer-list white xs-center xs-mb50">
                        <ul>
                            <li><a href="#">Acerca de</a></li>
                            <li><a href="#">Servicios</a></li>
                            <li><a href="#">Equipo</a></li>
                            <li><a href="#">Politíca de Privacidad</a></li>
                            <li><a href="#">Testimonios</a></li>
                            <li><a href="#">Soporte</a></li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-md-5 col-lg-5 col-sm-8 col-xs-12">
                    <div class="single-footer footer-subscribe white xs-center">
                        <h3 class="mb30 xs-font18">Suscribete a nuestro boletín</h3>
                        <p>Recibe las noticias de nuestros nuevos cursos que lanzaremos.</p>
                        <div class="subscriber-form-area mt50 wow fadeIn">
                            <!--<form action="#" class="subscriber-form mb100">
                                <input type="email" name="email" id="email" placeholder="Email Address">
                                <button type="submit">Subscribe</button>
                            </form>-->
                            <form id="mc-form" class="subscriber-form">
                                <label class="mt10" for="mc-email"></label>
                                <input type="email" id="mc-email" placeholder="ejemplo@ceir.mx">
                                <button type="submit" class="plus-btn"><i class="fa fa-paper-plane-o"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area white">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <div class="footer-copyright text-center wow fadeIn">
                        <p><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
Copyright &copy;<script>document.write(new Date().getFullYear());</script> Todos los derechos reservados | Sitio Web elaborado por <a href="https://www.victorvr.com" target="_blank">Victor Valencia Rico</a>
<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--FOOTER AREA END-->