<!--START TOP AREA-->
<header class="top-area" id="home">
    <div class="header-top-area" id="scroolup">
        <!--MAINMENU AREA-->
        <div class="mainmenu-area" id="mainmenu-area">
            <div class="mainmenu-area-bg"></div>
            <nav class="navbar">
                <div class="container">
                    <div class="navbar-header">
                        <a href="#home" class="navbar-brand"><img src="assets/img/RISUR_logo.png" alt="logo" style="width: 32px;"></a>
                    </div>
                    <div id="main-nav" class="stellarnav">
                        <ul id="nav" class="nav navbar-nav pull-right">
                            <li class="active"><a href="#home">Inicio</a></li>
                            <li><a href="#downloads">Descargas</a></li>
                            <li><a href="#autors">Autores</a></li>
                            <!-- <li><a href="#courses">Cursos</a></li> -->
                            <!-- <li><a href="#testmonial">Testimoniales</a></li>
                            <li><a href="#blog">Notas de Interés</a></li> -->
                            <!-- <li><a href="#apps">Aplicaciones</a></li>
                            <li><a href="#faqs">Preguntas</a></li>
                            <li><a href="#contact">Contacto</a></li> -->
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <!--END MAINMENU AREA END-->
    </div>

    <div style="background-color: #4582ec; color: white;" class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="intro-text">
                        <span class="skills"></span>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-top: 24px;">
                <div class="col-lg-8 col-lg-offset-2">
                    <div class="text-center">
                        <img src="assets/img/RISUR_logo.png" alt="" style="width: 200px; margin-top: -30px;">
                    </div>                    
                </div>
            </div>
            <div class="row" style="padding-top: 24px;">
                <div class="col-lg-8 col-lg-offset-2">
                    <h3 class="mb30 xs-font18 text-center" style="color: white">Riego por Surcos</h3>
                </div>
            </div>
            <div class="row" style="padding-top: 24px;">
                <div class="col-lg-8 col-lg-offset-2">
                    <p>RISUR es un software que se diseño con el fin de visualizar de forma gráfica el comportamiento del riego de parcelas por surcos, ya que permite la variación de los parámetros que intervienen. Convirtiéndose en una herramienta potencial y eficiente para la Asistencia Técnica en Riego y ofrecer a los productores del estado de Guanajuato un servicio técnico de calidad, el cual le permita hacer uso eficiente del agua y otros recursos.</p>
                </div>
            </div>
    
            <div class="col-lg-12 text-center" style="padding-top: 24px;">
                <iframe src="https://player.vimeo.com/video/143787694?color=c9ff23&amp;title=0&amp;byline=0&amp;portrait=0" width="640" height="360" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
            </div>
    
        </div>
    </div>

    <!--DOWNLOADS AREA-->
    <section class="course-area padding-top section-padding" id="downloads">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                    <div class="area-title text-center wow fadeIn">
                        <h2 class="xs-font26">Descargas</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-lg-offset-2">
                    <div class="panel panel-primary" style="margin-top: 24px;">
                        <div class="panel-heading">
                            <h3 class="panel-title">Instaladores</h3>
                        </div>
                        <div class="panel-body">

                            <div style="text-align: right; margin-bottom: 12px;">

                                <li class="dropdown" style="list-style-type: none;">
                                    <a class="dropdown-toggle btn btn-link" data-toggle="dropdown" href="#" aria-expanded="false">
                                    ¿Cómo saber que arquitectura tengo en mi equipo? <span class="caret"></span>
                                    </a>
                                    <ul class="dropdown-menu" style="right: 0; left: inherit;">
                                        <li><a href="assets/pdf/arquitectura/Arquitectura W10.pdf" target="_blank">En Windows 10</a></li>
                                        <li><a href="assets/pdf/arquitectura/Arquitectura W8.pdf" target="_blank">En Windows 8 y 8.1</a></li>
                                        <li><a href="assets/pdf/arquitectura/Arquitectura W7.pdf" target="_blank">En Windows 7</a></li>
                                        <li><a href="assets/pdf/arquitectura/Arquitectura WVista.pdf" target="_blank">En Windows Vista</a></li>
                                        <li><a href="assets/pdf/arquitectura/Arquitectura WXP.pdf" target="_blank">En Windows XP</a></li>
                                    </ul>
                                </li>

                            </div>

                            <table class="table" style="color: #2c3e50; margin-bottom: 12px;">
                                <thead>
                                    <tr>
                                        <th style="width: 90px;">Versión</th>
                                        <th>Fecha de publicación</th>
                                        <th style="width: 90px; text-align: center;">Manual</th>
                                        <th style="width: 90px; text-align: center;">32 bits</th>
                                        <th style="width: 90px; text-align: center;">64 bits</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="line-height: 42px;">2.3.3</td>
                                        <td style="line-height: 42px;">05-ABR-2021 / Última versión</td>
                                        <td>
                                            <li class="dropdown" style="list-style-type: none;">
                                                <a class="dropdown-toggle btn btn-link" data-toggle="dropdown" href="#" aria-expanded="false">
                                                    <i class="fa fa-eye"></i> Ver <span class="caret"></span>
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li><a href="assets/pdf/instalacion/Instalación RISUR v2.3.3 en W8-10.pdf" target="_blank">Para Windows 8, 8.1 y 10</a></li>
                                                    <li><a href="assets/pdf/instalacion/Instalación RISUR v2.3.3 en W7.pdf" target="_blank">Para Windows 7</a></li>
                                                    <li><a href="assets/pdf/instalacion/Instalación RISUR v2.3.3 en WVista.pdf" target="_blank">Para Windows Vista</a></li>
                                                    <li><a href="assets/pdf/instalacion/Instalación RISUR v2.3.3 en WXP.pdf" target="_blank">Para Windows XP</a></li>
                                                </ul>
                                            </li>
                                        </td>
                                        <td>
                                            <a id="32" href="https://www.dropbox.com/sh/nhox79bkm5hz4wr/AAB2tbL9yIZAJ1b3zSStSnQAa?dl=1" class="btn btn-link" title="Descargar (51.8 MB)">
                                                <i class="fa fa-download"></i> Descargar
                                            </a>
                                        </td>
                                        <td>
                                            <a id="64" href="https://www.dropbox.com/sh/54kkrmsxbr54hsj/AABEGb8caDw9ZoNiFgy8Mbvca?dl=1" class="btn btn-link" title="Descargar (65.6 MB)">
                                                <i class="fa fa-download"></i> Descargar
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <span class="text-muted" style="float: right;">Compatible con Windows XP, Vista, 7, 8, 8.1 y 10.</span>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-8 col-lg-offset-2">
                    <div class="panel panel-primary" style="margin-top: 24px;">
                        <div class="panel-heading">
                            <h3 class="panel-title">Preguntas frecuentes</h3>
                        </div>
                        <div class="panel-body">
                            <a href="assets/pdf/Activación RISUR.pdf" target="_blank" class="btn btn-link">
                                ¿Cómo activar el programa?
                            </a>
                            <br>
                            <a href="assets/pdf/Verificación RISUR.pdf" target="_blank" class="btn btn-link">
                                ¿Cómo verificar que el programa se instaló correctamente?
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!--DOWNLOADS AREA END-->

    <!--TESTMONIAL AREA AREA-->
    <section class="testmonial-area bg-theme section-padding" id="autors">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                    <div class="area-title text-center wow fadeIn">
                        <h2>Autores</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="autor-slider">
                        <div class="single-testmonial">
                            <!-- <div class="author-content mb20">
                                <p>“Aprendi a instalar y darle mantenimiento a los sistemas de riego por goteo en la zona Laja-Bajío.”</p>
                            </div> -->
                            <div class="author-name-image relative">
                                <div class="author-img mb20">
                                    <img src="assets/img/testmonial/pending_1.jpg" alt="">
                                </div>
                                <h4>M.C. Gustavo Magaña Sosa</h4>
                                <p>Maestro en Ciencias de la Hidrología</p>
                            </div>
                        </div>
                        <div class="single-testmonial">
                            <!-- <div class="author-content mb20">
                                <p>“Logré hacer uso eficiente del agua en mi rancho, y lo mejor es que pude ahorrar dinero aplicando los conocimientos obtenidos.”</p>
                            </div> -->
                            <div class="author-name-image relative">
                                <div class="author-img mb20">
                                    <img src="assets/img/testmonial/pending_1.jpg" alt="">
                                </div>
                                <h4>M.C. Bernardo Muñoz Hernández</h4>
                                <p>Maestro en Ciencias de la Hidrología</p>
                            </div>
                        </div>
                        <div class="single-testmonial">
                            <!-- <div class="author-content mb20">
                                <p>“Colaboré con otros técnicos y aprendí la importancia que tiene el agua en el estado de Guanajuato. Ahora, transmito lo aprendido a mis productores.”</p>
                            </div> -->
                            <div class="author-name-image relative">
                                <div class="author-img mb20">
                                    <img src="assets/img/testmonial/pending_1.jpg" alt="">
                                </div>
                                <h4>I.S.C. Víctor Valencia Rico</h4>
                                <p>Desarrollador de Software</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--TESTMONIAL AREA AREA END-->

</header>
<!--END TOP AREA-->

<footer-component></footer-component>