<!--FEATURES TOP AREA-->
<section class="features-top-area section-padding" id="features">
    <div class="container">
        <div class="row promo-content">
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box mb20 xs-mb0 wow fadeInUp padding30" data-wow-delay="0.1s">
                    <div class="box-icon features-box-icon">
                        <i class="fa fa-graduation-cap"></i>
                    </div>
                    <h3 class="box-title">Misión</h3>
                    <p>Ofrecer servicios integrales especializados de capacitación y consultoría a personas, empresas y organizaciones dedicadas a la irrigación.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb20 xs-mb0  wow fadeInUp padding30" data-wow-delay="0.2s">
                    <div class="box-icon features-box-icon">
                        <i class="icofont icofont-business-man-alt-1"></i>
                    </div>
                    <h3 class="box-title">Visión</h3>
                    <p>Ser líder en la promoción y transferencia de conocimiento especializado en materia de irrigación en México.</p>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                <div class="text-icon-box relative mb20 xs-mb0 wow fadeInUp padding30" data-wow-delay="0.3s">
                    <div class="box-icon features-box-icon">
                        <i class="fa fa-rocket"></i>
                    </div>
                    <h3 class="box-title">Valores</h3>
                    <p>Profesionalismo, Confianza, Responsabilidad, Honestidad y Compromiso</p>
                </div>
            </div>                
        </div>
    </div>
</section>
<!--FEATURES TOP AREA END-->    

<!--ABOUT TOP CONTENT AREA-->
<section class="section-padding bg-theme">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12">
                <div class="text-center wow fadeIn">
                    <h2 class="xs-font20">CEIR cuenta con los mejores expertos en el área de irrigación.</h2>
                    <p>Nuestros expertos compartirán contigo su conocimiento y experiencia para que puedas ser altamente competitivo en el uso eficiente del agua.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--ABOUT TOP CONTENT AREA END-->

<!--FUN FACT AREA AREA-->
<!--<section class="fun-fact-area center white relative padding-100-70" id="fact">
    <div class="area-bg" data-stellar-background-ratio="0.6"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-fun-fact mb30 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 class="font60 xs-font26"><span class="counter">500</span></h3>
                    <p class="font600">Estudiantes graduados</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-fun-fact mb30 wow fadeInUp" data-wow-delay="0.2s">
                    <h3 class="font60 xs-font26"><span class="counter">8</span></h3>
                    <p class="font600">Instructores altamente capacitados</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-fun-fact mb30 wow fadeInUp" data-wow-delay="0.3s">
                    <h3 class="font60 xs-font26"><span class="counter">5</span></h3>
                    <p class="font600">Cursos completos</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="single-fun-fact mb30 wow fadeInUp" data-wow-delay="0.4s">
                    <h3 class="font60 xs-font26"><span class="counter">350</span></h3>
                    <p class="font600">Estudiantes con empleo competitivo</p>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!--FUN FACT AREA AREA END-->